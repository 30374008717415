import React from 'react';
import { Link } from 'gatsby';

import Slider from 'react-animated-slider';

import 'react-animated-slider/build/horizontal.css';
import '../scss/carousel.scss';

const MobileCarousel = ({highlights, landingSlide}) => {
    const sliderStyles = (bgImageUrl) => {
        return ({
            backgroundImage: `url(${bgImageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        })
    };

    return (
        <div className='carousel'>
            <div className='overlay'/>

            <Slider infinite={true} duration={1500} autoplay={3500}>
                <div style={sliderStyles(landingSlide.mobileLandingBackground.file.url)}> 
                    <div className='centered'>
                        <div className='highlight'>
                            <h1 className='header'>{landingSlide.header}</h1>
                            <p className='subheader'>{landingSlide.subheader}</p>
                            <Link to='/reel' className='button1'>
                                <h2>Watch Reel</h2>
                            </Link>
                        </div>
                    </div>
                </div>
                {highlights.map((highlight, i) => {
                    if (!highlight.previewPhoto || !highlight.clientLogos) return false;

                    let slug = highlight.title.split(' ').join('-');
                    let styles = sliderStyles(highlight.previewPhoto.file.url)
                    return (
                        <div style={styles} key={i}>
                            <div className='centered'>
                                <div className='highlight'>
                                    <div className='logo'>
                                        <img src={`https:${highlight.clientLogos[0].file.url}`}></img>
                                    </div>
                                    <Link to={`/project/${slug}`} className='button2'>view project</Link>                   
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default MobileCarousel;