import React, { useState, useRef, createRef, useEffect, useLayoutEffect } from 'react';
import lottie from 'lottie-web';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
// import scrollSnapPolyfill from 'css-scroll-snap-polyfill'

import Head from '../components/Head';
import NavBar from '../components/NavBar';
import MobileCarousel from '../components/MobileCarousel';

import animation from '../animations/down_arrow3.json';
import animation2 from '../animations/movie_reel.json';

import '../scss/home_2.scss';

const Home = ({data}) => {
    const highlights = data.allContentfulHomePageHighlights.edges[0].node.projects;
    const homePageMeta = data.allContentfulHomePage.nodes[0]
    let animationContainer = createRef();
    let animationContainer2 = createRef();

    let [scrollableContentChildren, setScrollableContentChildren] = useState(false),
        scrollableContentChildrenRef = useRef(null);

    let [backgrounds, setBackgrounds] = useState(false),
        backgroundsRef = useRef(backgrounds);

    let [bgIndexState, _setBgIndex] = useState(0),
        bgIndexRef = useRef(bgIndexState)

    const setBgIndex = data => {
        bgIndexRef.current = data;
        _setBgIndex(data)
    }

    let [lastScrollTop, _setLastScrollTop] = useState(0),
        lastScrollTopRef = useRef(lastScrollTop)

    const setLastScrollTop = data => {
        lastScrollTopRef.current = data;
        _setLastScrollTop(data)
    }

    function visibleY (el) {
        let rect = el.getBoundingClientRect()
        let top = rect.top, height = rect.height
        el = el.parentNode;
        do {
          rect = el.getBoundingClientRect();
          if ((top <= rect.bottom) === false) return false;
          // Check if the element is out of view due to a container scrolling
          if ((top + height) <= rect.top) return false
          el = el.parentNode;
        } while (el !== document.body);
        // Check its within the document viewport
        return top <= document.documentElement.clientHeight;
    };
    
    
    function handleScroll (e) {
        let direction, directionBackgroundEl
        let currentScrollEl, tempIdx = 0
        let currentBackgroundEl, prevBackgroundEl, nextBackgroundEl
        let bgIndex = bgIndexRef.current;
        //edge cases
            
        if (bgIndex === 0)
            backgrounds.item(0).style.opacity = 1;
    
        if (bgIndex < 0)
            return setBgIndex(0), bgIndex = 0, tempIdx = 0;
    
        //these statements are for aggressive scrolling when using mouse wheel..
        //this shit was SO annoying. because for some reason it skips if you go insanely fast on mouse wheel
        //so top and bottom get manually assigned if it happens
    
        if (e.target.scrollTop === 0) {
            setBgIndex(0)
            bgIndex = 0;
        }
    
        let elem = e.target
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
            elem.scrollTop = elem.scrollHeight;
            setBgIndex(highlights.length - 1)
            bgIndex = highlights.length - 1
        }
    
        //find direction
    
        if (e.target.scrollTop < lastScrollTopRef.current) {
            tempIdx = bgIndex - 1;
            direction = 'up';
        }
        else {
            tempIdx = bgIndex + 1;
            direction = 'down';
        }
    
        let scrollable = scrollableContentChildren.item(tempIdx);
    
        if (!scrollable && bgIndex > 0)
            return;
    
        // console.log(bgIndex);
        //assign default variables relative to current highlight
        currentBackgroundEl = backgrounds.item(bgIndex)
        currentScrollEl = scrollableContentChildren.item(bgIndex);
        directionBackgroundEl = backgrounds.item(tempIdx)
    
        if (bgIndex !== 0)
            prevBackgroundEl = currentBackgroundEl.previousSibling
        if (bgIndex > highlights.length)
            nextBackgroundEl = currentBackgroundEl.nextSibling
    
    
        //flip z-index
        currentScrollEl.style.opacity = 1;
    
        backgrounds.forEach((background, i) => {
            if (bgIndex !== i) {
                background.style.display = "none";
                background.style.opacity = 0;
                // background.style.transform = 'scale(1)';
                
                //this is for aggressive scrolling
                if (bgIndex === 0)
                    background.style.display = "none"
            }
    
            return background.style.zIndex = (direction === "up" && bgIndex !== 0 ? i : highlights.length - i)
        })
    
        //apply display & default opacity styles
        if (directionBackgroundEl) {
            directionBackgroundEl.style.display = "flex"
            directionBackgroundEl.style.opacity = 1;
        }
    
        currentBackgroundEl.style.display = "flex";
        currentBackgroundEl.style.opacity = 1;
    
        if (direction === "up") {
            if (nextBackgroundEl) {
                nextBackgroundEl.style.display = "flex";
                nextBackgroundEl.style.opacity = 1;
            }
        } else if (prevBackgroundEl) {
    
            prevBackgroundEl.style.display = "none";
            prevBackgroundEl.style.opacity = 1;
        }
    
        //calculate distance of current
        // & apply relative opacity for transition
        if (!scrollable) {
            if (direction === 'up')
                scrollable = scrollableContentChildren.item(bgIndex)
            else
                scrollable = scrollableContentChildren.item(tempIdx)
        }
    
        let distance = scrollable.getBoundingClientRect().top;
        distance = distance / 1000
        distance = (distance > .9 && distance < 1 ? 1 : distance);
        if (distance < 0) distance = distance * -1;
    
        if (direction === 'down') {
            if (currentBackgroundEl.previousSibling)
                currentBackgroundEl.previousSibling.style.display = 'none';
    
    
            if (distance < .8) {
                currentBackgroundEl.style.opacity = distance
                // scrollable.style.opacity = (1 - distance) - .3
            }
            scrollable.style.opacity = (1 - distance)
    
            currentBackgroundEl.style.transform = `scale(${1.5 + (1 - distance)})`
            currentBackgroundEl.nextSibling.style.transform = `scale(${1})`
            
        } else if (bgIndex !== 0){
    
            if (currentBackgroundEl.nextSibling) 
                currentBackgroundEl.nextSibling.style.display = 'flex';
    
            if (distance < .8) {
                currentBackgroundEl.style.opacity = distance
            }
            scrollable.style.opacity = (1 - distance)
    
            currentBackgroundEl.previousSibling.style.transform = `scale(${1.5 + (distance)})`
            currentBackgroundEl.style.transform = `scale(${1})`
        }
    
        if (bgIndex === 0) {
            currentBackgroundEl.style.transform = `scale(1)`
        }
        currentScrollEl = scrollableContentChildren.item(bgIndex);
    
        if (!visibleY(currentScrollEl)) {
            setBgIndex(tempIdx)
            scrollable.style.opacity = 1;
        }
    
        setLastScrollTop(e.target.scrollTop)
    }
    

    useLayoutEffect(() => {
        setBackgrounds(backgroundsRef.current.childNodes);
        setScrollableContentChildren(scrollableContentChildrenRef.current.childNodes)

        //e, bgIndex, setBgIndex, lastScrollTopRef, setLastScrollTop, scrollableContentChildren, highlights, backgrounds
        if (backgrounds && scrollableContentChildren && window && navigator) {
            window.addEventListener('scroll', function(e) {
                return handleScroll(e)
            }, {passive: true})
        }

    }, [backgrounds, scrollableContentChildren, highlights, bgIndexRef])

    useEffect(() => {
        let anim = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animation
        });
        let anim2 = lottie.loadAnimation({
            container: animationContainer2.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation2
          });
        return () => {
            lottie.destroy()
            // anim2.destroy()
        }// optional clean up for unmounting
    }, []);

    const loadMeta = () => {
        let projects = [];
        highlights.map((project, i) => {
            if (project.previewPhoto && project.clientLogos) {
                if (!project.clientLogos[0]) return;
                let slug = project.title.split(' ').join('-');

                return (projects.push(
                    <div className='centered' key={i}>
                        <div className='highlight'>
                            <div className='logo'>
                                <img src={`https:${project.clientLogos[0].file.url}`}></img>
                            </div>
                            <Link to={`/project/${slug}`} className='button2'>view project</Link>                   
                        </div>
                    </div>
                ))
            }
        })

        return projects;
    }

    const loadBackgrounds = () => {
        let projects = [];

        highlights.map((project, i) => {
            if (!project.previewPhoto) return false;
            const sliderStyles = {
                zIndex: highlights.length - (i + 1),
                position: 'fixed'
            }
            // projects.push(<div className='background' style={sliderStyles}></div>)
            return projects.push(<Img className='background' style={sliderStyles} fluid={project.previewPhoto.fluid} key={i}></Img>)

        })
        return projects;
    }

    const [navOpen, setNavOpen] = useState(false);

    const navCallback = (state) => {        
        setNavOpen(state);
    }

    return (
        <div className='home'>
            <Head url='https://northboundfilms.com' parent='home'></Head>
            <div className='nav-container'>
                <NavBar parentCallback={navCallback}/>
                <div className={(navOpen ? 'disabled-content' : null)} />
                
                <MobileCarousel highlights={highlights} landingSlide={homePageMeta}/>

                <div className={`slides ${(navOpen ? 'shrink' : 'expand')}`} >
                    <div className='overlay transition2'/>

                    <div id='backgrounds' ref={backgroundsRef}>
                        <div className="background" id="landing" style={{
                                zIndex: highlights.length, display: "block"
                            }}>
                            <div id='video-background'>
                                <Img fluid={homePageMeta.landingBgVideoFirstFrame.fluid} className='first-frame'></Img>
                                <iframe src={`${homePageMeta.landingBgVideo}?background=1`} width="640" height="360" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                                {/* <iframe frameBorder='0'
                                    src={`${homePageMeta.landingBgVideo}?autoplay=1&loop=1&title=0&sidedock=0&controls=0`}/>  
                                */}
                            </div>
                        </div>
                        {loadBackgrounds()}
                    </div>

                    <div id='scrollable-content' ref={scrollableContentChildrenRef} onScroll={handleScroll}
                        style={{ position: (navOpen ? 'relative' : 'absolute')}}>
                        <div className='centered'>
                            <div className='hero-content'>
                                <h1 className='header'>{homePageMeta.header}</h1>
                                <p className='subheader'>{homePageMeta.subheader}</p>
                                <a href='/reel' className='button1'>
                                    <h2>Watch Reel</h2>
                                    <div className='animation2' ref={animationContainer2}></div>                            
                                </a>
                            </div>
                            <div className='animation-container'>
                                <div className='animation' ref={animationContainer}></div>                            
                            </div>
                        </div>
                        {loadMeta()}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home;

export const query = graphql`
    query {
        allContentfulHomePage {
            nodes {
                header
                subheader
                landingBgVideo
                mobileLandingBackground {
                    file {
                        url
                    }
                }
                landingBgVideoFirstFrame {
                    fluid(quality: 100) {
                        ...GatsbyContentfulFluid_withWebp
                    }
                }
            }
        }

        allContentfulHomePageHighlights {
            edges {
                node {
                    projects {
                        clientLogos {
                            file {
                                url
                            }
                        }
                        previewPhoto {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid_withWebp
                            }
                            file {
                                url
                            }
                        }
                        title
                    }
                }
            }
        }
    }
`;
